import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import axiosClient from './axiosClient';

export default function ProductReturnReasons() {

  const navigate = useNavigate();
  const location = useLocation();

  const { orderId } = location.state || {};

  const [paymentType, setPaymentType] = useState('');

  // find the payemnt type of the order
  useEffect(() => {
    const fetchPaymentType = async() => {
      try {
        const response = await axiosClient.post('/order/payment_type', 
          {
            "orderId": orderId
          }
        )
        if(response && response.data.length > 0) {
          const { payment_type } = response.data[0];
          setPaymentType(payment_type)
        } else {
          console.log('No payment type found for the given order.');
        }
      } catch(err) {
        console.log("An error occured =", err);
      }
    }

    if(orderId) {
      fetchPaymentType();
    }
  }, [orderId]);

  const [selectedReasons, setSelectedReasons] = useState([]);

  const reasons = [
    'Product was damaged',
    'Received wrong item',
    'Item did not match description',
    'Quality was not as expected',
    'No longer needed',
    'Other'
  ]

  const handleReasonChange = (reason) => {
    setSelectedReasons((prevSelected) => {
      if(prevSelected.includes(reason)) {
        return prevSelected.filter((r) => r !== reason);
      } else {
        return [...prevSelected, reason];
      }
    });
  };

  const handleContinueClick = () => {
    if(paymentType === 'cod') {
      navigate('/order/product_item/add_bank_details');
    } else {
      navigate('/');
    }
  }

  return (
    <div className='bg-body-secondary p-3'>
      <div className='bg-light p-3 mx-2 shadow'>
        <p className='fs-6 fw-semibold'>
          Can you tell us the reason for product return?
        </p>
        <div>
          {reasons.map((reason, index) => (
            <div key={index} className='form-check'>
              <input
                className='form-check-input'
                type='checkbox'
                value={reason}
                id={`reason-${index}`}
                checked={selectedReasons.includes(reason)}
                onChange={() => handleReasonChange(reason)}
              />
              <label className='form-check-label' htmlFor={`reason-${index}`}>
                {reason}
              </label>
            </div>
          ))}
        </div>
        <div className='shadow'>
          <FloatingLabel
            controlId='floatingTextArea'
            label='Please enter your reasons.'
            className='mb-2'
          >
            <Form.Control as="textarea" placeholder='Please enter your reasons' />
          </FloatingLabel>
        </div>
        <div>
          <Button variant='primary' className='w-100' onClick={handleContinueClick}>Continue</Button>
        </div>
      </div>
    </div>
  )
}
