import React, { useState, useEffect } from "react";
import './ImageSlider.css'; // For CSS


const ImageSlider = () => {
  const images = [
    "https://via.placeholder.com/600x400?text=Slide+1",
    "https://via.placeholder.com/600x400?text=Slide+2",
    "https://via.placeholder.com/600x400?text=Slide+3",
    "https://via.placeholder.com/600x400?text=Slide+4",
    "https://via.placeholder.com/600x400?text=Slide+5"
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const slideTime = 3000; // Slide duration in milliseconds

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, slideTime);

    return () => clearInterval(slideInterval);
  }, [currentIndex, images.length, slideTime]);

    return (
        <div className="slider-wrapper">
            <div className="slider-container">
                <div
                    className="slider"
                    style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                >
                    {images.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`Slide ${index + 1}`}
                            className="slider-image"
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ImageSlider;


