import React, { Suspense, lazy, useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Helmet } from 'react-helmet';
import {
    Link,
    useNavigate,
    useParams
} from "react-router-dom";
import HashLoader from 'react-spinners/HashLoader';
import logo from '../img/logo.jpeg';
import LocationInput from './Ambulance Booking/PickUpAndDropLocation';
import axiosClient from './axiosClient';
import './style.css';
import { useAuth } from './useAuth';


const ChoosePinCodeModal = lazy(() => import('./ChoosePinCodeModal'));
const MobileSidebar = lazy(() => import('./mobileSidebar'));

// import { Helmet, HelmetProvider } from 'react-helmet-async';
require('dotenv').config();
export default function Header() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [mobileShow, setMobileShow] = useState(false);
    const handleMobileClose = () => setMobileShow(false);
    const handleMobileShow = () => setMobileShow(true);


    const [pickupLocationshow, setPickupLocationshow] = useState(false);
    const handlePickUpLocationClose = () => setPickupLocationshow(false);
    const handlePickupLocationShow = () => setPickupLocationshow(true);

    const customStyles = {
        content: {
            overflowY: 'hidden',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        document.body.style.backgroundColor = 'rgb(171 165 165 / 15%)';
        document.body.style.overflow = 'hidden';
    }

    function closeModal() {
        document.body.style.overflow = 'unset';
        setIsOpen(false);
    }


    const navigate = useNavigate();
    //main for connecting backend with Session
    axiosClient.defaults.withCredentials = true;
    const [numOfItem, setnumOfItem] = useState(0)
    const [loggedIn, setLoggedIn] = useState([])
    const [locations, setLocation] = useState([])
    const [products, setProducts] = useState([])
    // const [chooseLocation, setChooseLocation] = useState([])
    const [chooseProduct, setChooseProduct] = useState([])
    const [chooseLocation, setChooseLocation] = useState([])
    const [selectLocation, setSelectLocation] = useState()
    const [userLocation, setUserLocation] = useState()
    const [active, setActive] = useState(false);

    const [ambulanceButtonText, setAmbulanceButtonText] = useState('Book Ambulance');


    // custom state for useAuth logout and isLoggedIn
    const { isLoggedIn, logout, role, userId } = useAuth();
    // useEffect(() => {
    //     // Get data from localStorage
    //     const storedData = localStorage.getItem('user_id');

    //     // Log the data to the console
    //     if (storedData) {
    //         console.log(JSON.parse(storedData));
    //     } else {
    //         console.log('No data found in localStorage');
    //     }
    // }, []); // Empty dependency array means this runs once when the component mounts

    const handleResize = () =>
    {
        if (window.innerWidth < 1060) {
            setAmbulanceButtonText('Ambulance');
          } else {
            setAmbulanceButtonText('Book Ambulance');
          }
    }

    useEffect(() =>{
      handleResize(); // Set initial text based on current width
      window.addEventListener('resize', handleResize); // Listen for resize events

      // Cleanup event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [])


    let current_pin_code;
    current_pin_code = localStorage.getItem('current_pin_code');

    useEffect(() => {
        const profileFetch = () => {
            axiosClient.get(`/profile-details/${userId}/${role}`)
            .then(res => {
                if (res.data.length >= 2) {
                    setnumOfItem(res.data[0]);
                    // alert("if block")
                    setLoggedIn(res.data[1]);
                    // alert(JSON.stringify(loggedIn)+"if block")
                    // setUserLocation(res.data[2]);
                    if(res.data[2] !== null && res.data[2] !== undefined) {
                        localStorage.setItem("current_pin_code", res.data[2]);
                    }

                } else {
                    setnumOfItem(0);
                    // setLoggedIn(res.data[1]);
                    // alert("else block")
                    // alert(JSON.stringify(loggedIn)+"else block")
                }
            })
        }
        if(isLoggedIn && userId && role) {
            profileFetch();
        } else {
            setnumOfItem(0);
        }
    }, [userId, role]);
    useEffect(() => {
        if (userLocation !== 0 || userLocation !== undefined) {
            setSelectLocation(userLocation)
        }
    }, [userLocation])
    useEffect(() => {
        axiosClient.get(`/locations`)
            .then(res => {
                setLocation(res.data);
            })
    }, [])
    useEffect(() => {
        axiosClient.get(`/search`)
            .then(res => {
                setProducts(res.data);
            })
    }, [])

    // Commented login hardcode
    // var login = false;
    // if (loggedIn !== undefined) {
    //     login = true;
    // }
    // const handleLogout = async () => {
    //     try {
    //         const response = await axiosClient.post(`/profile`);
    //         if (response.data.success) {
    //             setLoggedIn(undefined);
    //             // alert(JSON.stringify(loggedIn)+"set as undefined")
    //             localStorage.removeItem('LogedIn');
    //             localStorage.removeItem('user_id');
    //             localStorage.clear();
    //             navigate('/')
    //         } else {
    //             // Handle logout failure
    //             console.error(response.data.message);
    //         }
    //     } catch (error) {
    //         console.error('An error occurred:', error);
    //     }
    // };

    const param = useParams();
    if (param.selectLocation !== undefined) {
    } else {
    }
    const [values, setValues] = useState({
        input: '',
        from: 'header'
    })
    const handleFilter = (event) => {
        setValues(prev => ({ ...prev, [event.target.name]: [event.target.value] }))
        const searchword = event.target.value.toLowerCase();
        const newFilter = products.filter((value) => {
            return value.name.toLowerCase().includes(searchword);
        });
        if (searchword === "") {
            setChooseProduct([]);
        } else {
            setChooseProduct(newFilter);
        }
    };
    const setValueTOFilter = async (name) => {
        setValues({
            input: name,

        })
    }
    // const [locationvalue, setLocationValues] = useState({
    //     input: '',
    //     from: 'header'
    // })
    // const handleLocationFilter = (event) => {
    //     setLocationValues(prev => ({ ...prev, [event.target.name]: [event.target.value] }))
    //     const searchword = event.target.value.toLowerCase();
    //     console.log("searchword" +  searchword)
    //     const newFilter = locations.filter((value) => {
    //         console.log(value)
    //         return value.includes(searchword);
    //     });
    //     if (searchword === "") {
    //         setChooseLocation([]);
    //     } else {
    //         setChooseLocation(newFilter);
    //     }
    // };
    // const setValueLocationTOFilter = async (pin_code) => {
    //     setValues({
    //         input: pin_code,

    //     })
    // }

    const [searchLocation, setSearchLocation] = useState([]);

    const [searchValue, setSearchValue] = useState({
        input: ''
    })



    const searchMedicne = async () => {
        console.log(current_pin_code);
        if (current_pin_code === null) {
            try {
                const response = await axiosClient.post(`/search`, values);
                if (response.data !== null) {
                    navigate(`/medicines/${values.input}`,
                        {
                            state: {
                                product: response.data[0],
                                image: response.data[1],
                                lab: response.data[2],
                                labImage: response.data[3],
                                doctor: response.data[4],
                                doctorImage: response.data[5],
                                medicineShop: response.data[6],
                                medicineShopImage: response.data[7],
                                location: selectLocation
                            }
                        })
                    setValues({
                        input: ''
                    });
                    setChooseProduct([]);
                } else {
                    console.error(response.data.message);
                }
            } catch (error) {
                console.error('An error occurred:', error);
            }

        } else {
            try {
                const response = await axiosClient.post(`/search/${current_pin_code}`, values);
                if (response.data !== null) {
                    navigate(`/medicines/${values.input}`,
                        {
                            state: {
                                product: response.data[0],
                                image: response.data[1],
                                lab: response.data[2],
                                labImage: response.data[3],
                                doctor: response.data[4],
                                doctorImage: response.data[5],
                                medicineShop: response.data[6],
                                medicineShopImage: response.data[7],
                                location: selectLocation
                            }
                        })
                    setValues({
                        input: ''
                    });
                    setChooseProduct([]);
                } else {
                    console.error(response.data.message);
                }
            } catch (error) {
                console.error('An error occurred:', error);
            }

        }
    }


    // const searchMedicne = async () => {
    //     try {
    //         const response = await axiosClient.post(`/search`, values);
    //         if (response.data !== null) {
    //             navigate(`/medicines/${values.input}`,
    //                 {
    //                     state: {
    //                         product: response.data[0],
    //                         image: response.data[1],
    //                         lab: response.data[2],
    //                         labImage: response.data[3],
    //                         doctor: response.data[4],
    //                         doctorImage: response.data[5],
    //                         medicineShop: response.data[6],
    //                         medicineShopImage: response.data[7],
    //                         location: selectLocation
    //                     }
    //                 })
    //             setValues({
    //                 input: ''
    //             });
    //             setChooseProduct([]);
    //         } else {
    //             console.error(response.data.message);
    //         }
    //     } catch (error) {
    //         console.error('An error occurred:', error);
    //     }
    // }
    const handleClick = event => {
        setChooseProduct([]);
    };

    // console.log(current_pin_code)
    useEffect(() => {
        setSearchValue({
            input: current_pin_code,
        });

    }, [current_pin_code])
    // console.log(searchValue)
    return (
        // <HelmetProvider>
        <>
            <Helmet>
                <title>healthhepta.com</title>
                <meta name="description" content=" Affordable healthcare services for you.Search pharmacy near you. Book your lab test with our platform. Find Doctor near you .schedule Doctor appointment online 24/7 even after hours." />
            </Helmet>
            {active ? <>
                <div className="HamburgerMenu" style={{ width: '50vw' }}>
                    <h5>Hi User</h5>
                    <ul>
                        <li><Link to='/profile' className='text-light li'>Profile</Link></li>
                        <li>Doctors</li>
                        <li>Medicine Shops</li>
                        <li>Lab Tests</li>
                        <li>Clinics</li>
                        <li>Log Out</li>
                    </ul>
                    {/* <span onClick={() => setActive(!active)}> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="7vw" height="5vh" fill="white" className="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                    {/* </span> */}
                </div>
            </> : <>

            </>
            }
            <div className='Header' >
                <nav className="navbar navbar-expand-md">
                    <div className="right">
                        <Link className="navbar-brand" to='/' style={{ margin: '0 2vw', display: 'flex', alignItems: 'center' }}>
                            <img src={logo} alt="Logo" className="d-inline-block align-text-top logo-image" width="50vw" height="50vh" style={{ borderRadius: "50%" }} />
                            <div className="container-fluid line-header"  >
                                {/* onClick={() => setActive(!active)} */}
                                {active ? <>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="7vw" height="5vh" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </> 
                                : 
                                <>
                                {/* Mobile sidebar */}
                                <button class="navbar-toggler" type="button" onClick={handleMobileShow}>
                                    <span className="navbar-toggler-icon line-icon-header"></span>
                                </button>
                                <Offcanvas show={mobileShow} onHide={handleMobileClose} placement="start"  style={{ width: '60%' }}>
                                    <Offcanvas.Header closeButton>
                                <Offcanvas.Title>
                                    <h5>Mobile Sidebar</h5>
                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <div className='wrapper align-content-start'> 
                                            <Suspense fallback={<HashLoader color="#36d7b7" />}>
                                            <MobileSidebar onHide={handleMobileClose} />
                                            </Suspense> 
                                        </div>
                                    </Offcanvas.Body>
                                </Offcanvas>
                                </>
                                }
                            </div>
                            <div>
                                <h5 className='text-light mx-1 logo-text' style={{ fontWeight: '700' }} >Healthhepta</h5>
                            </div>
                        </Link>

                    </div>
                    <div className="container-fluid left header-left" style={{ display: 'flex', justifyContent: 'space-evenly' }} >
                        <div className="dropdown me-2 dropdown-location "  >
                            {/* <select value={selectLocation} onChange={e => setSelectLocation(e.target.value)} className="btn btn-secondary header-location-1 header-location-mobile" aria-expanded="false" >
                                <option defaultValue={'choose your location..'} >choose your Pin Code..</option>
                                {locations.map((location, index) => (
                                    <option key={index} value={location.pin_code}>{location.pin_code}</option>
                                )
                                )}
                            </select> */}
                            {/* onClick={() => setLocationValueTOFilter(location.pin_code)} */}
                            {/* <input className="form-control header-location-1 header-location-mobile" name='input' onChange={handleLocationFilter} placeholder="Search your pin code here" value={locationvalue.input}  /> */}
                            {/* <input className="form-control" name='input' placeholder="Pin Code" value={searchValue.input} onClick={handleShow} /> */}
                            {/* <button className="btn btn-light dropdown-toggle header-location-1 header-location-mobile" name='input' type="button" placeholder="Pin Code" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" value={searchValue.input} onClick={handleShow} >
                                Pin Code
                            </button> */}
                            <button
                                className="btn btn-light dropdown-toggle header-location-1 header-location-mobile"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                onClick={handleShow}
                            >
                                {searchValue.input !== null ? searchValue.input : 'Pin Code..'}
                            </button>

                            <Offcanvas show={show} onHide={handleClose}>
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title>
                                        <h5>Find Nearby Services</h5>
                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <div className='dis-flex'> <Suspense fallback={<HashLoader color="#36d7b7" />}> <ChoosePinCodeModal onHide={handleClose} /></Suspense> </div>
                                </Offcanvas.Body>
                            </Offcanvas>
                        </div>
                        <div className="search  me-2 search-location" >
                            <div style={{ display: 'flex' }}>
                                <input className="form-control" name='input' onChange={handleFilter} placeholder="Search Doctors, Clinics, Hospitals, Diseases Etc" value={values.input} style={{ width: '22vw', fontSize: '0.9em', borderTopLeftRadius: '6px', borderTopRightRadius: '0px', borderBottomLeftRadius: '6px', borderBottomRightRadius: '0px' }} />
                                <button type="button" onClick={searchMedicne} className="btn" style={{ backgroundColor: '#febd69', color: 'black', borderTopLeftRadius: '0px', borderTopRightRadius: '6px', borderBottomLeftRadius: '0px', borderBottomRightRadius: '6px' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                                    </svg>
                                </button>
                            </div>
                            {chooseProduct.length !== 0 && (
                                <div className="inputResult" onClick={handleClick}>
                                    {chooseProduct.map((product, index) => {
                                        return <p onClick={() => setValueTOFilter(product.name)} style={{ textDecoration: 'none', color: 'black' }}><p style={{ cursor: 'pointer', padding: '0px' }} key={index}>{product.name}</p></p>
                                    }
                                    )}
                                </div>
                            )}
                        </div>

                        <div className='login-order' style={{ alignItems: "center" }}>
                            <Link to='/sub-admin/login' target='_Block' style={{ textDecoration: 'none' }}><p className='btn btn-outline-info mx-1' style={{ display: 'flex', color: 'blue', border: '2px solid blue', fontWeight: '600', padding: '5px' }}>Business</p></Link>
                            {/* <Link to='/ambulance/booking' style={{ textDecoration: 'none', marginBottom: '5px' }}><p className='btn btn-outline-info for-dealer-btn business-btn-text mx-1' style={{ display: 'flex', color: 'blue', border: '2px solid blue', fontWeight: '700' }}>Book Ambulance</p></Link> */}
                            <div>

                                <span style={{ textDecoration: 'none', marginBottom: '5px' }} onClick={handlePickupLocationShow}><p className='btn btn-outline-info mx-1' style={{ color: 'blue', border: '2px solid blue', fontWeight: '600', padding: '5px' }}>{ambulanceButtonText}</p></span>

                                <Offcanvas show={pickupLocationshow} onHide={handlePickUpLocationClose}>
                                    <Offcanvas.Header closeButton>
                                        <Offcanvas.Title>
                                            <h5>Ambulance Booking</h5>
                                        </Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <div className='dis-flex'> <Suspense fallback={<HashLoader color="#36d7b7" />}> <LocationInput onHide={handlePickUpLocationClose} /></Suspense> </div>
                                    </Offcanvas.Body>
                                </Offcanvas>

                            </div>

                            <Link to='/cart' style={{ textDecoration: 'none' }} >
                                <div className="cart d-flex justify-content-start align-items-center" style={{ marginLeft: "auto", textDecoration: 'none' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="5vw" height="5vh" fill="currentColor" className="bi bi-cart p-0 drop-dwon-profile" viewBox="0 0 16 16" >
                                        <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                    </svg>
                                    <p className='px-2 cartItemNo' style={{ color: 'white', marginLeft: '-10px', marginRight: '10px', backgroundColor: 'orange', borderRadius: '5px' }}>{numOfItem}</p>
                                </div>
                            </Link>
                            {isLoggedIn ?
                                // <div className="buttom profile-icon mx-3">
                                //     <div className="dropdown">
                                //         <span className="btn  dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" style={{ padding: '0px' }}>
                                //             <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-person shadow drop-dwon-profile " style={{ backgroundColor: '' }} viewBox="0 0 16 16">
                                //                 <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                                //             </svg>
                                //         </span>
                                <div className="buttom profile-icon mx-3">
                                <div className="dropdown">
                                  <span className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" style={{ padding: '0px' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="bi bi-person shadow drop-dwon-profile" viewBox="0 0 16 16" style={{ width: '30px', height: '30px' }}>
                                      <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" 
                                        fill="none" 
                                        stroke="currentColor" 
                                        strokeWidth="1"
                                      />
                                    </svg>
                                  </span>
                                        <ul className='dropdown-menu'>
                                            {isLoggedIn && role === 'customer' && (
                                                <li><Link to='/profile' className="dropdown-item">Profile</Link></li>
                                            )}
                                            {isLoggedIn && role === 'admin' && (
                                                <li><Link to='/profile' className="dropdown-item">Profile</Link></li>
                                            )}
                                            {isLoggedIn && role === 'partner' && (
                                                <li><Link to='/partner/home' className="dropdown-item">Profile</Link></li>
                                            )}
                                            <li><Link className="dropdown-item" onClick={logout} >Log out</Link></li>
                                        </ul>
                                        {/* {
                                            loggedIn.role === 'customer' ? <>
                                                <ul className="dropdown-menu">
                                                    <li><Link to='/profile' className="dropdown-item">Profile</Link></li>
                                                    <li><Link className="dropdown-item" onClick={logout} >Log out</Link></li>
                                                </ul>
                                            </> : <>

                                            </>
                                        } */}
                                        {/* {
                                            loggedIn.role === 'admin' ? <>
                                                <ul className="dropdown-menu">
                                                    <li><Link to='/profile' className="dropdown-item">Profile</Link></li>
                                                    <li><Link className="dropdown-item" onClick={logout} >Log out</Link></li>
                                                </ul>
                                            </> : <>

                                            </>
                                        } */}
                                        {/* {
                                            loggedIn.role === 'customer' ? <>
                                                <ul className="dropdown-menu">
                                                    <li><Link to='/partner/home' className="dropdown-item">Profile</Link></li>
                                                    <li><Link className="dropdown-item" onClick={logout} >Log out</Link></li>
                                                </ul>
                                            </> : <>

                                            </>
                                        } */}
                                    </div>
                                </div>
                                :
                                <div>
                                    <Link to='/login'>
                                        <div className="buttom login-text">
                                            <p style={{ margin: '0px' }} className="btn btn-primary login-btn-text"> <p style={{ margin: '0px' }}>Login</p> </p>
                                        </div>
                                    </Link>
                                </div>

                            }
                        </div>
                    </div>

                </nav>

                <div className='d-flex justify-content-between' 
                style={{ backgroundColor: "rgb(7, 219, 193)" }}>
                    <div className="dropdown-location-under"  >
                        {/* <select value={selectLocation} onChange={e => setSelectLocation(e.target.value)} className=" header-location-mobile" aria-expanded="false" >
                            <option defaultValue={'choose your location..'} ><p>choose your Pin Code..</p></option>
                            {locations.map((location, index) => (
                                <option key={index} value={location.pin_code}><p>{location.pin_code}</p></option>
                            )
                            )}
                        </select> */}
                        {/* <input className="form-control" name='input' placeholder="Pin Code" value={searchValue.input} onClick={handleShow} /> */}
                        <select className="btn btn-light dropdown-toggle header-location-1 header-location-mobile" name='input' type="button" placeholder="Pin Code" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" onClick={handleShow} >
                            <option value={searchValue.input}>{searchValue.input !== null ? <>{searchValue.input}</> : <>Pin Code..</>}</option>
                        </select>

                        <Offcanvas show={show} onHide={handleClose}>
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title>
                                    <h5>Find Nearby Services</h5>
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <div className='dis-flex'> <Suspense fallback={<HashLoader color="#36d7b7" />}> <ChoosePinCodeModal onHide={handleClose} /></Suspense> </div>
                            </Offcanvas.Body>
                        </Offcanvas>

                        {/* <input className="form-control  header-location-mobile" name='input' onChange={handleLocationFilter} placeholder="Pin Code" value={searchValue.input} />


                        {searchLocation.length !== 0 && (
                            <div className="inputResult " >
                                {searchLocation.map((location, index) => {
                                    return <span onClick={() => setLocationValueTOFilter(location.pin_code)} style={{ textDecoration: 'none', color: 'black' }}  ><div style={{ cursor: 'pointer', padding: '0px' }} key={index}  >{location.pin_code}</div></span>
                                }
                                )}
                            </div>
                        )} */}


                    </div>
                    <div className="search location-search-under" >
                        <div style={{ display: 'flex', width: '60vw', marginBottom: '5px' }}>
                            <input className="form-control " name='input' onChange={handleFilter} placeholder="Search Doctors, Clinics, Hospitals, Diseases Etc" value={values.input} style={{ fontSize: '0.9em', borderTopLeftRadius: '6px', borderTopRightRadius: '0px', borderBottomLeftRadius: '6px', borderBottomRightRadius: '0px' }} />
                            <button type="button" onClick={searchMedicne} className="btn" style={{ backgroundColor: '#febd69', color: 'black', borderTopLeftRadius: '0px', borderTopRightRadius: '6px', borderBottomLeftRadius: '0px', borderBottomRightRadius: '6px' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                                </svg>
                            </button>
                        </div>
                        {chooseProduct.length !== 0 && (
                            <div className="inputResult" onClick={handleClick}>
                                {chooseProduct.map((product, index) => {
                                    return <p onClick={() => setValueTOFilter(product.name)} style={{ textDecoration: 'none', color: 'black' }}><p style={{ cursor: 'pointer', padding: '0px' }} key={index}>{product.name}</p></p>
                                }
                                )}
                            </div>
                        )}
                    </div>
                </div>

            </div>
        </>
        // </HelmetProvider>
    )
}
