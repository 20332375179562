import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export default function CallDoctorModal({ callDoctorModal, closeModal }) {

  const handleDoctorCall = () =>
  {
    alert('Doctor has been called!');
    closeModal();
  }

  return (
    <div>
      <Modal show={callDoctorModal} onHide={closeModal} centered className='rounded-2'>
        <div style={{background: 'rgb(7, 219, 193)'}}>
            <Modal.Header style={{background: 'rgb(7, 219, 193)', borderBottom: '2px solid rgb(141 184 249)'}} closeButton>
                <Modal.Title className='text-center w-100'>Call a Doctor</Modal.Title>
            </Modal.Header>
            <Modal.Body className='text-center p-4' style={{background: 'rgb(7, 219, 193)'}}>
                <p className='fs-5 mb-4'>Need medical assistance? Don't hesitate to reach out to a healthcare professional.</p>
                <Button
                    variant='primary'
                    onClick={handleDoctorCall}
                    className='fw-bold px-3 py-3 rounded fs-6'
                >
                    Call Now
                </Button>
            </Modal.Body>
        </div>
      </Modal>
    </div>
  )
}