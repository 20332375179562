import React, { useRef, useEffect, useState } from 'react';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import ImageSlider from "./ImageSlider";
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

const GridLayout = () => {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        variableWidth: true,
        centerMode: true,
        centerPadding: '60px',
    };

    const [scrollPosition, setScrollPosition] = useState(0);
    const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const handleScroll = () => {
      setScrollPosition(container.scrollLeft);
    };

    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const items = [
    {
      id: 1,
      name: 'Product 1',
      price: '$19.99',
      image: 'https://via.placeholder.com/150x150',
    },
    {
      id: 2,
      name: 'Product 2',
      price: '$29.99',
      image: 'https://via.placeholder.com/150x150',
    },
    {
      id: 3,
      name: 'Product 3',
      price: '$39.99',
      image: 'https://via.placeholder.com/150x150',
    },
    {
      id: 4,
      name: 'Product 4',
      price: '$49.99',
      image: 'https://via.placeholder.com/150x150',
    },
    {
      id: 5,
      name: 'Product 5',
      price: '$59.99',
      image: 'https://via.placeholder.com/150x150',
    },
    {
      id: 6,
      name: 'Product 6',
      price: '$69.99',
      image: 'https://via.placeholder.com/150x150',
    },
    {
      id: 7,
      name: 'Product 7',
      price: '$79.99',
      image: 'https://via.placeholder.com/150x150',
    },
    {
      id: 8,
      name: 'Product 8',
      price: '$89.99',
      image: 'https://via.placeholder.com/150x150',
    },
    {
      id: 9,
      name: 'Product 9',
      price: '$99.99',
      image: 'https://via.placeholder.com/150x150',
    },
    {
      id: 10,
      name: 'Product 10',
      price: '$109.99',
      image: 'https://via.placeholder.com/150x150',
    },
    {
        id: 11,
        name: 'Product 11',
        price: '$59.99',
        image: 'https://via.placeholder.com/150x150',
      },
      {
        id: 12,
        name: 'Product 12',
        price: '$69.99',
        image: 'https://via.placeholder.com/150x150',
      },
      {
        id: 13,
        name: 'Product 13',
        price: '$79.99',
        image: 'https://via.placeholder.com/150x150',
      },
      {
        id: 14,
        name: 'Product 14',
        price: '$89.99',
        image: 'https://via.placeholder.com/150x150',
      },
      {
        id: 15,
        name: 'Product 15',
        price: '$99.99',
        image: 'https://via.placeholder.com/150x150',
      },
      {
        id: 16,
        name: 'Product 16',
        price: '$109.99',
        image: 'https://via.placeholder.com/150x150',
      },
  ]; 

  const handleScroll = (e) => {
    setScrollPosition(e.currentTarget.scrollLeft);
  };


    return (
        <div>
            <div className="container-grid">
                {Array.from({ length: 9 }).map((_, index) => (
                    <div key={index} className="grid-item">
                        {/* Trending label for the first item (for example) */}
                        {(index === 0 || index === 6 || index ===5) && (
                            <span className="trending-label">TRENDING</span>
                        )}
                        {/* Image with rating overlay */}
                        <div className="image-container">
                            <img
                                src={`https://via.placeholder.com/150x150?text=Image+${index + 1}`}
                                alt={`Image ${index + 1}`}
                            />

                            {/* Star rating on the bottom left */}
                            <div className="rating-overlay">
                                <span className="rating-text">4.{index + 1}</span>
                                <FontAwesomeIcon icon={faStar} className="star-icon" />
                            </div>
                        </div>
                        {/*<img
                            src={`https://via.placeholder.com/150x150?text=Image+${index + 1}`}
                            alt={`Image ${index + 1}`}
                        />*/}
                    </div>
                ))}
            </div>
            <div className="scrollable-section" ref={containerRef} onScroll={handleScroll}>
                <div className="items-container" style={{ transform: `translateX(-${scrollPosition}px)` }}>
                    {items.map((item) => (
                        <div key={item.id} className="item-scroll">
                            <img src={item.image} alt={item.name} />
                            <h4>{item.name}</h4>
                            <p>{item.price}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div>
              <h1>Automatic Image Slider</h1>
              <ImageSlider />
            </div>
            {/*<div className="carousel-container">
                <Slider {...settings}>
                    {Array.from({ length: 15 }).map((_, index) => (
                        <div key={index} className="carousel-item">
                            <img
                                src={`https://via.placeholder.com/150x150?text=Image+${index + 1}`}
                                alt={`Image ${index + 1}`}
                            />
                        </div>
                    ))}
                </Slider>
            </div>*/}
        </div>
    );
};

export default GridLayout;