

import React, { useState, useEffect } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import './style.css'
import { useNavigate } from 'react-router-dom';

function LocationInput({ onHide }) {
    const [pickupLocation, setPickupLocation] = useState('');
    const [pickupLatLng, setPickupLatLng] = useState(null);
    const [dropOffLocation, setDropOffLocation] = useState('');
    const [dropOffLatLng, setDropOffLatLng] = useState(null);

    const navigate = useNavigate();


    useEffect(() => {
        // Fetch user's current location when component mounts
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    const latLng = { lat: latitude, lng: longitude };
                    setPickupLatLng(latLng);
                    setDropOffLatLng(latLng);
                    geocodeByLatLng(latLng);
                },
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    }, []);

    const geocodeByLatLng = (latLng) => {
        geocodeByAddress(`${latLng.lat},${latLng.lng}`)
            .then((results) => {
                if (results && results.length > 0) {
                    setPickupLocation(results[0].formatted_address);
                }
            })
            .catch((error) => console.error('Error', error));
    };

    const handlePickupChange = (address) => {
        setPickupLocation(address);
    };

    const handlePickupSelect = (address) => {
        setPickupLocation(address);
        geocodeByAddress(address)
            .then((results) => getLatLng(results[0]))
            .then((latLng) => setPickupLatLng(latLng))
            .catch((error) => console.error('Error', error));
    };

    const handleDropOffChange = (address) => {
        setDropOffLocation(address);
    };

    const handleDropOffSelect = (address) => {
        setDropOffLocation(address);
        geocodeByAddress(address)
            .then((results) => getLatLng(results[0]))
            .then((latLng) => setDropOffLatLng(latLng))
            .catch((error) => console.error('Error', error));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Pickup Location:', pickupLocation);
        console.log('Pickup LatLng:', pickupLatLng);
        console.log('Drop-off Location:', dropOffLocation);
        console.log('Drop-off LatLng:', dropOffLatLng);
        onHide();
        navigate('/ambulance/booking',
            {
                state: {
                    pickupLocation: pickupLocation,
                    pickupLatLng: pickupLatLng,
                    dropOffLocation: dropOffLocation,
                    dropOffLatLng: dropOffLatLng
                }
            })
    };

    return (
        <div>
            <form onSubmit={handleSubmit} style={{ textAlign: 'initial', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <label className='p-2'>
                    <span className='p-2' style={{ fontWeight: '700' }}> Pickup Location:</span>
                    <PlacesAutocomplete
                        value={pickupLocation}
                        onChange={handlePickupChange}
                        onSelect={handlePickupSelect}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                <input
                                    {...getInputProps({
                                        placeholder: 'Enter Pickup Location',
                                        className: 'location-search-input',
                                    })}
                                    className='inputStyle'
                                />
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion) => {
                                        const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                </label>
                <br />
                <label className='p-2'>
                    <span className='p-2' style={{ fontWeight: '700' }}>Drop-off Location:</span>
                    <PlacesAutocomplete
                        value={dropOffLocation}
                        onChange={handleDropOffChange}
                        onSelect={handleDropOffSelect}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                <input
                                    {...getInputProps({
                                        placeholder: 'Enter Drop-off Location',
                                        className: 'location-search-input',
                                    })}
                                    className='inputStyle'
                                />
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion) => {
                                        const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                </label>
                <br />
                <button type="submit" className='btn' style={{ width: '50%', color: 'white', backgroundColor: '#6775ec' }}>Submit</button>
            </form>
        </div>
    );
}

export default LocationInput;


// import React, { useState } from 'react';
// import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
// import './style.css'
// import { useNavigate } from 'react-router-dom';

// function LocationInput() {
//     const [pickupLocation, setPickupLocation] = useState('');
//     const [dropOffLocation, setDropOffLocation] = useState('');
//     const [pickupLatLng, setPickupLatLng] = useState(null);
//     const [dropOffLatLng, setDropOffLatLng] = useState(null);

//     const navigate = useNavigate();

//     const handlePickupChange = (address) => {
//         setPickupLocation(address);
//     };

//     const handlePickupSelect = (address) => {
//         setPickupLocation(address);
//         geocodeByAddress(address)
//             .then((results) => getLatLng(results[0]))
//             .then((latLng) => setPickupLatLng(latLng))
//             .catch((error) => console.error('Error', error));
//     };

//     const handleDropOffChange = (address) => {
//         setDropOffLocation(address);
//     };

//     const handleDropOffSelect = (address) => {
//         setDropOffLocation(address);
//         geocodeByAddress(address)
//             .then((results) => getLatLng(results[0]))
//             .then((latLng) => setDropOffLatLng(latLng))
//             .catch((error) => console.error('Error', error));
//     };

//     const handleSubmit = (event) => {
//         event.preventDefault();
//         console.log('Pickup Location:', pickupLocation);
//         console.log('Pickup LatLng:', pickupLatLng);
//         console.log('Drop-off Location:', dropOffLocation);
//         console.log('Drop-off LatLng:', dropOffLatLng);
//         navigate('/ambulance/booking',
//         {
//             state: {
//                 pickupLocation: pickupLocation,
//                 pickupLatLng: pickupLatLng,
//                 dropOffLocation: dropOffLocation,
//                 dropOffLatLng: dropOffLatLng
//             }
//         })
//     };

//     return (
//         <div>
//             <form onSubmit={handleSubmit} style={{ textAlign: 'initial', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
//                 <label  className='p-2'>
//                     <span className='p-2' style={{ fontWeight: '700' }}> Pickup Location:</span>
//                     <PlacesAutocomplete
//                         value={pickupLocation}
//                         onChange={handlePickupChange}
//                         onSelect={handlePickupSelect}
//                     >
//                         {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
//                             <div>
//                                 <input
//                                     {...getInputProps({
//                                         placeholder: 'Enter Pickup Location',
//                                         className: 'location-search-input',
//                                     })}
//                                     className='inputStyle'
//                                 />
//                                 <div className="autocomplete-dropdown-container">
//                                     {loading && <div>Loading...</div>}
//                                     {suggestions.map((suggestion) => {
//                                         const className = suggestion.active
//                                             ? 'suggestion-item--active'
//                                             : 'suggestion-item';
//                                         return (
//                                             <div
//                                                 {...getSuggestionItemProps(suggestion, {
//                                                     className,
//                                                 })}
//                                             >
//                                                 <span>{suggestion.description}</span>
//                                             </div>
//                                         );
//                                     })}
//                                 </div>
//                             </div>
//                         )}
//                     </PlacesAutocomplete>
//                 </label>
//                 <br />
//                 <label  className='p-2'>
//                     <span className='p-2' style={{ fontWeight: '700' }}>Drop-off Location:</span>
//                     <PlacesAutocomplete
//                         value={dropOffLocation}
//                         onChange={handleDropOffChange}
//                         onSelect={handleDropOffSelect}
//                     >
//                         {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
//                             <div>
//                                 <input
//                                     {...getInputProps({
//                                         placeholder: 'Enter Drop-off Location',
//                                         className: 'location-search-input',
//                                     })}
//                                     className='inputStyle'
//                                 />
//                                 <div className="autocomplete-dropdown-container">
//                                     {loading && <div>Loading...</div>}
//                                     {suggestions.map((suggestion) => {
//                                         const className = suggestion.active
//                                             ? 'suggestion-item--active'
//                                             : 'suggestion-item';
//                                         return (
//                                             <div
//                                                 {...getSuggestionItemProps(suggestion, {
//                                                     className,
//                                                 })}
//                                             >
//                                                 <span>{suggestion.description}</span>
//                                             </div>
//                                         );
//                                     })}
//                                 </div>
//                             </div>
//                         )}
//                     </PlacesAutocomplete>
//                 </label>
//                 <br />
//                 <button type="submit" className='btn' style={{ width: '50%', color: 'white', backgroundColor: '#6775ec' }} >Submit</button>
//             </form>
//         </div>
//     );
// }

// export default LocationInput;


// import React, { useState } from 'react';
// import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
// import './style.css'


// function LocationInput() {
//   const [pickupLocation, setPickupLocation] = useState('');
//   const [dropOffLocation, setDropOffLocation] = useState('');

//   const handlePickupChange = (address) => {
//     setPickupLocation(address);
//   };

//   const handlePickupSelect = (address) => {
//     setPickupLocation(address);
//     console.log('Pickup Location:', address);
//   };

//   const handleDropOffChange = (address) => {
//     setDropOffLocation(address);
//   };

//   const handleDropOffSelect = (address) => {
//     setDropOffLocation(address);
//     console.log('Drop-off Location:', address);
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     console.log('Pickup Location:', pickupLocation);
//     console.log('Drop-off Location:', dropOffLocation);
//   };

//   return (
//     <div>
//       <form onSubmit={handleSubmit}  style={{ textAlign: 'initial',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center' }}>
//         <label className='p-2' >
//          <span className='p-2'  style={{fontWeight: '700'}}> Pickup Location:</span>
//           <PlacesAutocomplete
//             value={pickupLocation}
//             onChange={handlePickupChange}
//             onSelect={handlePickupSelect}
//           >
//             {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
//               <div>
//                 <input
//                   {...getInputProps({
//                     placeholder: 'Enter Pickup Location',
//                     className: 'location-search-input',
//                   })}
//                   className='inputStyle'
//                 />
//                 <div className="autocomplete-dropdown-container">
//                   {loading && <div>Loading...</div>}
//                   {suggestions.map((suggestion) => {
//                     const className = suggestion.active
//                       ? 'suggestion-item--active'
//                       : 'suggestion-item';
//                     return (
//                       <div
//                         {...getSuggestionItemProps(suggestion, {
//                           className,
//                         })}
//                       >
//                         <span>{suggestion.description}</span>
//                       </div>
//                     );
//                   })}
//                 </div>
//               </div>
//             )}
//           </PlacesAutocomplete>
//         </label>
//         <br />
//         <label  >
//          <span className='p-2'  style={{fontWeight: '700'}}>Drop-off Location:</span>
//           <PlacesAutocomplete
//             value={dropOffLocation}
//             onChange={handleDropOffChange}
//             onSelect={handleDropOffSelect}
//           >
//             {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
//               <div>
//                 <input
//                   {...getInputProps({
//                     placeholder: 'Enter Drop-off Location',
//                     className: 'location-search-input',
//                   })}
//                   className='inputStyle'

//                 />
//                 <div className="autocomplete-dropdown-container">
//                   {loading && <div>Loading...</div>}
//                   {suggestions.map((suggestion) => {
//                     const className = suggestion.active
//                       ? 'suggestion-item--active'
//                       : 'suggestion-item';
//                     return (
//                       <div
//                         {...getSuggestionItemProps(suggestion, {
//                           className,
//                         })}
//                       >
//                         <span>{suggestion.description}</span>
//                       </div>
//                     );
//                   })}
//                 </div>
//               </div>
//             )}
//           </PlacesAutocomplete>
//         </label>
//         <br />
//         <button type="submit"  className='btn' style={{ width: '50%', color: 'white', backgroundColor: '#6775ec' }}>Submit</button>
//       </form>
//     </div>
//   );
// }

// export default LocationInput;

// import React, { useState } from 'react';
// import AutocompleteMap from '../Map/AutocompleteMap';

// function LocationInput() {
//     const [pickupLocation, setPickupLocation] = useState('');
//     const [dropOffLocation, setDropOffLocation] = useState('');

//     const handlePickupChange = (event) => {
//         setPickupLocation(event.target.value);
//     };

//     const handleDropOffChange = (event) => {
//         setDropOffLocation(event.target.value);
//     };

//     const handleSubmit = (event) => {
//         event.preventDefault();
//         // You can do something with the pickupLocation and dropOffLocation values here
//         console.log('Pickup Location:', pickupLocation);
//         console.log('Drop-off Location:', dropOffLocation);
//         // For example, you can send this data to an API, or perform any other necessary actions
//     };

//     const handlePickUpSelect = (place) => {
//         console.log(place)
//         setPickupLocation(place);

//     };
//     const handleDropOffSelect = (place) => {
//         console.log(place)
//         setDropOffLocation(place);

//     };

//     return (
//         <div style={{width:'100%'}}>
//             <form onSubmit={handleSubmit}>

//                 <div className='p-2' style={{ textAlign: 'initial', fontWeight: '700' }} >
//                     <label className='p-2'  >Pickup Location:</label><br></br>
//                     {/* <input
//                         className='m-2 p-1'
//                         type="text"
//                         value={pickupLocation}
//                         onChange={handlePickupChange}
//                         required
//                         style={{ width: '90%', border: '1px solid black' }} /> */}
//                     {/* <AutocompleteMap setSelected={setSelected} /> */}
//                     {/* <AutocompleteMap onPlaceSelect={handlePickUpSelect} /> */}
//                     <AutocompleteMap  />


//                 </div>
//                 <div className='p-2' style={{ textAlign: 'initial', fontWeight: '700' }} >
//                     <label className='p-2'  >Drop-off Location:</label><br></br>
//                     {/* <input
//                         className='m-2 p-1'
//                         type="text"
//                         value={dropOffLocation}
//                         onChange={handleDropOffChange}
//                         required
//                         style={{ width: '90%', border: '1px solid black' }} /> */}
//                     <AutocompleteMap onPlaceSelect={handleDropOffSelect} />

//                 </div>


//                 <button type='submit' className='btn' style={{ width: '90%', color: 'white', backgroundColor: '#6775ec' }}>Submit</button>

//                 {/* <label>
//                     Pickup Location:
//                     <input
//                         type="text"
//                         value={pickupLocation}
//                         onChange={handlePickupChange}
//                     />
//                 </label>
//                 <br />
//                 <label>
//                     Drop-off Location:
//                     <input
//                         type="text"
//                         value={dropOffLocation}
//                         onChange={handleDropOffChange}
//                     />
//                 </label>
//                 <br />
//                 <button type="submit">Submit</button> */}
//             </form>
//         </div>
//     );
// }

// export default LocationInput;
